<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form-group label="Type" label-for="type-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-select v-model="filter.type" :options="types"/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filter.search" type="search" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter.search" @click="filter.search = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="tableRows > perPage" class="mt-4">
      <b-col>
        <b-form-group label="Per page:" label-for="per-page-selector" label-cols="3">
          <b-form-select id="per-page-selector" v-model="perPage" :options="perPageValues"/>
        </b-form-group>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <p>Devices: {{ tableRows }}</p>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-table striped hover :fields="fields" :filter="filter" :filter-function="filterFunc" :items="items" :current-page="curPage" :per-page="perPage" no-provider-filtering no-provider-sorting no-provider-paging stacked="md" show-empty small primary-key="id" @filtered="onFiltered">
          <template #cell(IP)="data">
            {{ data.value }}
            <clipboard-button :value="data.value"/>
          </template>
          <template #cell(VPNIP)="data">
            {{ data.value }}
            <clipboard-button :value="data.value"/>
          </template>
          <template #cell(detailsButton)="data">
            <b-button :to="{name: 'Device', params: {id: data.item.id}}" size="sm" variant="light">...</b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="tableRows > perPage" class="mt-4">
      <b-col>
      </b-col>
      <b-col>
        <b-pagination class="justify-content-end" v-model="curPage" :per-page="perPage" :total-rows="tableRows"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { format as formatIP } from '../../utils/formatIP'
import { format as formatDT } from '../../utils/formatDateTime'
export default {
  name: 'device-table',
  props: ['items'],
  data () {
    return {
      tableRows: 0,
      curPage: 1,
      perPage: 10,
      perPageValues: [10, 50, 100, { value: 0, text: 'All' }],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'number', label: 'Number', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'IP', label: 'IP', sortable: true, formatter: v => (formatIP(v)) },
        { key: 'VPNIP', label: 'VPN IP', sortable: true, formatter: v => (formatIP(v)) },
        { key: 'version', label: 'Version', sortable: true },
        { key: 'OSVersion', label: 'OS Version', sortable: true },
        { key: 'created', label: 'Created', sortable: true, formatter: v => (formatDT(v)) },
        { key: 'lastData', label: 'Last data', sortable: true, formatter: v => (formatDT(v)) },
        { key: 'lastActivity', label: 'Last activity', sortable: true, formatter: v => (formatDT(v)) },
        { key: 'detailsButton', label: '', sortable: false }
      ],
      filter: {
        type: null,
        search: null
      },
      types: [
        { value: null, text: 'All' },
        { value: ['bay'], text: 'Bays' },
        { value: ['vacc'], text: 'Vacuum cleaners' },
        { value: ['tex', 'cwt'], text: 'Token exchangers' },
        { value: ['fmdserver'], text: 'Fiscal servers' },
        { value: ['odesk'], text: 'Operator desktops' }
      ]
    }
  },
  mounted () {
    this.tableRows = this.items.length
  },
  methods: {
    filterFunc (row, filter) {
      if (filter.type) {
        if (!filter.type.includes(row.type)) {
          return false
        }
      }
      if (!filter.search) {
        return true
      }
      const t = row.name.toLowerCase() + row.IP.toLowerCase() + row.VPNIP.toLowerCase()
      return t.includes(filter.search.toLowerCase())
    },
    onFiltered (filteredItems) {
      this.tableRows = filteredItems.length
      this.curPage = 1
    }
  }
}
</script>

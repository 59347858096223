<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Id</b-th>
              <b-th>Name</b-th>
              <b-th>EMail</b-th>
              <b-th>Disabled</b-th>
              <b-th>Notes</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Id'>{{ value.id }}</b-td>
              <b-td class='text-left' stacked-heading='Name'>{{ value.name }}</b-td>
              <b-td class='text-left' stacked-heading='EMail'>{{ value.email }}</b-td>
              <b-td class='text-left' stacked-heading='Disabled'><b-form-checkbox :checked="value.disabled" disabled/></b-td>
              <b-td class='text-left' stacked-heading='Notes'>{{ value.notes }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'admin-info',
  props: ['value']
}
</script>

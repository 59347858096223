<template>
  <b-table :fields="fields" :items="items" @row-clicked="(row, index) => $emit('row-clicked', row, index)" striped hover no-provider-filtering no-provider-sorting show-empty small/>
</template>
<script>
export default {
  name: 'public-subscriptions',
  props: ['items'],
  emits: ['row-clicked'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'price', label: 'Price', sortable: true }
      ]
    }
  }
}
</script>

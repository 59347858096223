<template>
  <b-table :fields="fields" :items="items" @row-clicked="(row, index) => $emit('row-clicked', row, index)" striped hover no-provider-filtering no-provider-sorting show-empty small>
    <template #cell(status)="data">
      <span :class="statusClass(data.value)">{{ data.value }}</span>
    </template>
  </b-table>
</template>
<script>
export default {
  name: 'object-subscriptions',
  props: ['items'],
  emits: ['row-clicked'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'duration', label: 'Duration', sortable: false },
        { key: 'price', label: 'Price', sortable: false },
        { key: 'created', label: 'Created', sortable: true },
        { key: 'started', label: 'Started', sortable: true },
        { key: 'ends', label: 'Ends', sortable: true },
        { key: 'userEmail', label: 'User', sortable: false },
        { key: 'status', label: 'Status', sortable: true }
      ]
    }
  },
  methods: {
    statusClass (value) {
      switch (value) {
        case 'failed': return 'text-danger'
        case 'active': return 'text-success'
        case 'expired': return 'text-warning'
      }
      return ''
    }
  }
}
</script>

import { configure, extend } from 'vee-validate'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from '@/components/SidebarPlugin'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// asset imports
import '@/assets/scss/argon.scss'
import '@/assets/vendor/nucleo/css/nucleo.css'
import '@/assets/vendor/fontawesome/css/fontawesome.css'
import '@/assets/vendor/fontawesome/css/brands.css'
import '@/assets/vendor/fontawesome/css/solid.css'

import * as rules from 'vee-validate/dist/rules'
import messages from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
      }
    })
  }
}

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-table striped hover :fields="fields" :filter="filter" :filter-included-fields="filterOn" :items="items" @row-clicked="rowHandler" noProviderFiltering noProviderSorting stacked="md" show-empty small/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'user-objects',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'region', label: 'Region', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'organization', label: 'Organization', sortable: false },
        { key: 'timeZone', label: 'Time zone', sortable: false },
        { key: 'tokenCost', label: 'Token cost', sortable: true },
        { key: 'rootPassword', label: 'Root password', sortable: false },
        { key: 'dataKey', label: 'Data key', sortable: false },
        { key: 'NTPServer', label: 'NTP server', sortable: false }
      ],
      filter: null,
      filterOn: ['name', 'country', 'region', 'city', 'address', 'organization']
    }
  },
  methods: {
    rowHandler (record, index) {
      this.$router.push({ name: 'Object', params: { id: record.id } })
    }
  }
}
</script>

<template>
  <span>
    <span :class="color" v-b-tooltip.hover :title="tooltip">{{ validity.status }}</span><span>&nbsp;({{nb}}&nbsp;&mdash; {{na}})</span>
  </span>
</template>
<script>
import { certValidity } from '../../utils/certValidity'
export default {
  name: 'cert-validity',
  props: ['nb', 'na'],
  data () {
    return {
      validity: { status: '<undefined>' },
      color: 'text-secondary',
      tooltip: ''
    }
  },
  mounted () {
    this.validity = certValidity(new Date(this.nb), new Date(this.na))
    switch (this.validity.status) {
      case 'inactive':
        this.color = 'text-dark'
        break
      case 'active':
        this.color = 'text-success'
        break
      case 'expiring':
        this.color = 'text-warning'
        break
      case 'expired':
        this.color = 'text-danger'
        break
    }
    this.tooltip = this.validity.daysRemain + ' days remain'
  }
}
</script>

import axios from 'axios'
import store from '../store'
export const get = async (url, params = {}) => {
  try {
    return axios.get('admin/' + url, { headers: { Authorization: 'Bearer ' + store.getters.token }, params: params })
  } catch (error) {
    console.log(error)
    throw error
  }
}
export const post = async (url, json, params = {}) => {
  try {
    return axios.post('admin/' + url, json, { headers: { Authorization: 'Bearer ' + store.getters.token }, params: params })
  } catch (error) {
    console.log(error)
    throw error
  }
}
export const put = async (url, json, params = {}) => {
  try {
    return axios.put('admin/' + url, json, { headers: { Authorization: 'Bearer ' + store.getters.token }, params: params })
  } catch (error) {
    console.log(error)
    throw error
  }
}
export const del = async (url, params = {}) => {
  try {
    return axios.delete('admin/' + url, { headers: { Authorization: 'Bearer ' + store.getters.token }, params: params })
  } catch (error) {
    console.log(error)
    throw error
  }
}
export const barePUT = async (url, json, params = {}) => {
  const a = axios.create({ baseURL: axios.defaults.baseURL })
  return a.put('admin/' + url, json, { params: params })
}
export const barePUTWithToken = async (url, json, params = {}) => {
  const a = axios.create({ baseURL: axios.defaults.baseURL })
  return a.put('admin/' + url, json, { headers: { Authorization: 'Bearer ' + store.getters.token }, params: params })
}
export const barePOST = async (url, json, params = {}) => {
  const a = axios.create({ baseURL: axios.defaults.baseURL })
  return a.post('admin/' + url, json, { params: params })
}

<template>
  <b-container class="mw-100" v-if="info.driver">
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Driver</b-th>
              <b-th>Printer status</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Driver'>{{ info.driver }}</b-td>
              <b-td class='text-left' stacked-heading='Printer status'>{{ info.printer_status }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row v-if="info.driver === 'uaprro'">
      <b-col v-if="info.driver_status.keysInfo">
        <h4>Keys</h4>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Container:</b-th>
              <b-th>CMP:</b-th>
              <b-th>Keys:</b-th>
              <b-th>Certs (local/external):</b-th>
              <b-th>Key type:</b-th>
              <b-th>Cert issuer:</b-th>
              <b-th>Cert subject:</b-th>
              <b-th>OCSP:</b-th>
              <b-th>AD Time Stamping:</b-th>
              <b-th>Cert validity:</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Container:'>{{ info.driver_status.keysInfo.key_file_type }} ({{ info.driver_status.keysInfo.key_file }})</b-td>
              <b-td class='text-left' stacked-heading='CMP:'>{{ info.driver_status.keysInfo.cmp }}</b-td>
              <b-td class='text-left' stacked-heading='Keys:'>{{ info.driver_status.keysInfo.key_store_key_num }}</b-td>
              <b-td class='text-left' stacked-heading='Certs (local/external):'>{{ info.driver_status.keysInfo.key_store_cert_num }} / {{ info.driver_status.keysInfo.external_cert_num }}</b-td>
              <b-td class='text-left' stacked-heading='Key type:'>{{ info.driver_status.keysInfo.signing_key.description }}</b-td>
              <b-td class='text-left' stacked-heading='Cert issuer:'><b-link href="#" @click="showName(info.driver_status.keysInfo.signing_cert.issuer_name)" v-b-tooltip.hover :title="issuer">{{ truncate(issuer, 30) }}</b-link></b-td>
              <b-td class='text-left' stacked-heading='Cert subject:'><b-link href="#" @click="showName(info.driver_status.keysInfo.signing_cert.subject_name)" v-b-tooltip.hover :title="subject">{{ truncate(subject, 30) }}</b-link></b-td>
              <b-td class='text-left' stacked-heading='OCSP:'>{{ info.driver_status.keysInfo.signing_cert.ocsp }}</b-td>
              <b-td class='text-left' stacked-heading='AD Time Stamping:'>{{ info.driver_status.keysInfo.signing_cert.ts }}</b-td>
              <b-td class='text-left' stacked-heading='Cert validity:'><cert-validity :nb="info.driver_status.keysInfo.signing_cert.not_before" :na="info.driver_status.keysInfo.signing_cert.not_after"/></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col v-if="info.driver_status.session">
        <h4>Session</h4>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Id</b-th>
              <b-th>Start</b-th>
              <b-th>Duration</b-th>
              <b-th>Local number</b-th>
              <b-th>Cash sum</b-th>
              <b-th>Cashless sum</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Id'>{{ info.driver_status.session.id }}</b-td>
              <b-td class='text-left' stacked-heading='Start'>{{ info.driver_status.session.dateTime }}</b-td>
              <b-td class='text-left' stacked-heading='Duration'>{{ info.driver_status.session.duration }}</b-td>
              <b-td class='text-left' stacked-heading='Local number'>{{ info.driver_status.session.local_number }}</b-td>
              <b-td class='text-left' stacked-heading='Cash sum'>{{ info.driver_status.session.cash_sum }}</b-td>
              <b-td class='text-left' stacked-heading='Cashless sum'>{{ info.driver_status.session.cashless_sum }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col v-if="info.driver_status.lastOperation">
        <h4>Last operation</h4>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Type</b-th>
              <b-th>Moment</b-th>
              <b-th>Error</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Type'>{{ info.driver_status.lastOperation.type }}</b-td>
              <b-td class='text-left' stacked-heading='Moment'>{{ info.driver_status.lastOperation.dateTime }}</b-td>
              <b-td class='text-left' stacked-heading='Error'>{{ info.driver_status.lastOperation.error }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row v-else-if="info.driver === 'novitus'">
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Command</b-th>
              <b-th>Check id</b-th>
              <b-th>Code</b-th>
              <b-th>Message</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Command'>{{ info.driver_status.command }}</b-td>
              <b-td class='text-left' stacked-heading='Check id'>{{ info.driver_status.checkId }}</b-td>
              <b-td class='text-left' stacked-heading='Code'>{{ info.driver_status.code }}</b-td>
              <b-td class='text-left' stacked-heading='Message'>{{ info.driver_status.message }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row v-else-if="info.driver === 'tremol'">
        <b-table-simple hower small caption-top stacked v-if="info.driver_status.error">
          <b-thead>
            <b-tr>
              <b-th>Error</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Error'>{{ info.driver_status.error }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple hower small caption-top stacked v-if="info.driver_status.messages">
          <b-thead>
            <b-tr>
              <b-th>Messages</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Messages'>
                <div v-for="message in info.driver_status.messages">
                  {{ message }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
    </b-row>
    <b-row v-else>
      <b-col>
        <h1>Unsupported fiscal driver '{{ info.driver }}'</h1>
      </b-col>
    </b-row>
    <b-modal id="name-info" hide-footer>
      <b-table-simple hower small caption-top stacked>
        <b-thead>
          <b-tr>
            <b-th v-for="ni in this.nameInfo" :key="ni.object">{{ ni.object }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td v-for="ni in this.nameInfo" class="text-left" :stacked-heading="ni.object" :key="ni.object">{{ ni.value }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </b-container>
</template>
<script>
import { getDeviceTelemetry } from '../../api/devices'
import { format } from '../../utils/formatTimestamp'
import { certValidity } from '../../utils/certValidity'
import CertValidity from './CertValidity.vue'

const fromNameInfo = ni => {
  const o = ni.find(e => e.object === 'organizationName')
  const cn = ni.find(e => e.object === 'commonName')
  if (o) {
    if (cn) {
      return o.value + ' / ' + cn.value
    } else {
      return o.value
    }
  } else {
    if (cn) {
      return cn.value
    } else {
      return '<unknown>'
    }
  }
}

export default {
  name: 'fmds-telemetry',
  components: {
    CertValidity
  },
  props: ['deviceId'],
  data () {
    return {
      info: {},
      nameInfo: null
    }
  },
  methods: {
    showName (ni) {
      this.nameInfo = ni
      this.$bvModal.show('name-info')
    },
    truncate (str, n) {
      return (str.length > n) ? str.slice(0, n - 1) + '...' : str
    }
  },
  computed: {
    certValidity: function () {
      if (!this.info || !this.info.driver_status || !this.info.driver_status.keysInfo) {
        return ''
      }
      const notBefore = new Date(this.info.driver_status.keysInfo.signing_cert.not_before)
      const notAfter = new Date(this.info.driver_status.keysInfo.signing_cert.not_after)
      const r = certValidity(notBefore, notAfter)
      if (r.valid) {
        return 'Valid'
      }
      return 'Not valid'
    },
    issuer: function () {
      if (!this.info || !this.info.driver_status || !this.info.driver_status.keysInfo) {
        return ''
      }
      return fromNameInfo(this.info.driver_status.keysInfo.signing_cert.issuer_name)
    },
    subject: function () {
      if (!this.info || !this.info.driver_status || !this.info.driver_status.keysInfo) {
        return ''
      }
      return fromNameInfo(this.info.driver_status.keysInfo.signing_cert.subject_name)
    }
  },
  async mounted () {
    this.info = await getDeviceTelemetry('fmds', this.deviceId)
    if (this.info.driver_status) {
      if (this.info.driver_status.session) {
        this.info.driver_status.session.dateTime = format(this.info.driver_status.session.start)
      }
      if (this.info.driver_status.lastOperation) {
        this.info.driver_status.lastOperation.dateTime = format(this.info.driver_status.lastOperation.moment)
      }
    }
    if (this.info.printer_status) {
      this.info.printer_status = this.info.printer_status.join(', ')
    } else {
      this.info.printer_status = 'No printer'
    }
  }
}
</script>

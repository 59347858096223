<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :items="items" noProviderSorting stacked="md" show-empty small/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'device-sessions',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'start', label: 'Start', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true }
      ]
    }
  }
}
</script>

<template>
  <b-container>
    <b-row class="mt-4">
      <b-col>
        <b-form-group label="Per page:" label-for="per-page-selector" label-cols="3">
          <b-form-select id="per-page-selector" v-model="perPage" :options="perPageValues"/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-pagination v-model="curPage" :per-page="perPage" :total-rows="tableRows" v-if="tableRows > perPage"/>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <p>Sessions: {{ tableRows }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :items="items" :current-page="curPage" :per-page="perPage" no-provider-sorting stacked="md" show-empty small @row-clicked="(record, index) => $emit('row-clicked', record, index)"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'session-history-table',
  props: ['items', 'fields'],
  data () {
    return {
      tableRows: 0,
      curPage: 1,
      perPage: 10,
      perPageValues: [10, 50, 100, { value: 0, text: 'All' }]
    }
  },
  beforeUpdate () {
    this.tableRows = this.items.length
  }
}
</script>

<template>
  <b-form-group label="Interval" label-for="interval-from" label-cols-sm="1" label-align-sm="left" label-size="sm" class="mb-0" >
    <b-row>
      <b-col cols="5">
        <b-form-input id="interval-from" v-model="fromV" type="date" placeholder="From"></b-form-input>
      </b-col>
      <b-col cols="5">
        <b-form-input id="interval-to" v-model="toV" type="date" placeholder="To"></b-form-input>
      </b-col>
      <b-col cols="2">
        <b-button :disabled="!fromV || !toV" @click="$emit('show', fromV, toV)">Show</b-button>
      </b-col>
    </b-row>
  </b-form-group>
</template>
<script>
export default {
  name: 'interval-selector',
  props: ['from', 'to'],
  data () {
    return {
      fromV: this.from,
      toV: this.to
    }
  }
}
</script>

<template>
  <b-table striped hover :fields="fields" :items="items" no-provider-filtering no-provider-sorting stacked="md" show-empty small/>
</template>
<script>
export default {
  name: 'subnet-table',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'net_ip', label: 'Net IP', sortable: true }
      ]
    }
  }
}
</script>

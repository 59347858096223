import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseNav from '@/components/Navbar/BaseNav'
import BaseDropdown from '@/components/BaseDropdown.vue'
import RegionTable from '@/components/Tables/Regions.vue'
import Interval from '@/components/Interval.vue'
import AdminInfo from '@/components/Admin/Info.vue'
import AdminActiveSessions from '@/components/Admin/ActiveSessions.vue'
import ObjectInfo from '@/components/Object/Info.vue'
import DeviceTable from '@/components/Tables/Devices.vue'
import UserTable from '@/components/Tables/Users.vue'
import ObjectTable from '@/components/Tables/Objects.vue'
import SubnetTable from '@/components/Tables/Subnets.vue'
import PublicSubscriptionTable from '@/components/Tables/Subscriptions/Public.vue'
import ObjectSubscriptionTable from '@/components/Tables/Subscriptions/Object.vue'
import DeviceInfo from '@/components/Device/Info.vue'
import DeviceTelemetry from '@/components/Device/Telemetry.vue'
import FMDSTelemetry from '@/components/Device/FMDSTelemetry.vue'
import BayTelemetry from '@/components/Device/BayTelemetry.vue'
import CWTTelemetry from '@/components/Device/CWTTelemetry.vue'
import VacCTelemetry from '@/components/Device/VacCTelemetry.vue'
import DeviceSessions from '@/components/Device/Sessions.vue'
import DeviceSessionHistory from '@/components/Device/SessionHistory.vue'
import UserInfo from '@/components/User/Info.vue'
import UserObjects from '@/components/User/Objects.vue'
import UserSessions from '@/components/User/Sessions.vue'
import SessionHistoryTable from '@/components/Sessions/HistoryTable.vue'
import SessionsAdmin from '@/components/Sessions/Admin.vue'
import SessionsUser from '@/components/Sessions/User.vue'
import SessionsDevice from '@/components/Sessions/Device.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ClipboardButton from '@/components/ClipboardButton.vue'

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(Interval.name, Interval)
    Vue.component(AdminInfo.name, AdminInfo)
    Vue.component(AdminActiveSessions.name, AdminActiveSessions)
    Vue.component(ObjectInfo.name, ObjectInfo)
    Vue.component(DeviceTable.name, DeviceTable)
    Vue.component(UserTable.name, UserTable)
    Vue.component(ObjectTable.name, ObjectTable)
    Vue.component(SubnetTable.name, SubnetTable)
    Vue.component(PublicSubscriptionTable.name, PublicSubscriptionTable)
    Vue.component(ObjectSubscriptionTable.name, ObjectSubscriptionTable)
    Vue.component(RegionTable.name, RegionTable)
    Vue.component(DeviceInfo.name, DeviceInfo)
    Vue.component(DeviceTelemetry.name, DeviceTelemetry)
    Vue.component(FMDSTelemetry.name, FMDSTelemetry)
    Vue.component(BayTelemetry.name, BayTelemetry)
    Vue.component(CWTTelemetry.name, CWTTelemetry)
    Vue.component(VacCTelemetry.name, VacCTelemetry)
    Vue.component(DeviceSessions.name, DeviceSessions)
    Vue.component(DeviceSessionHistory.name, DeviceSessionHistory)
    Vue.component(UserInfo.name, UserInfo)
    Vue.component(UserObjects.name, UserObjects)
    Vue.component(UserSessions.name, UserSessions)
    Vue.component(SessionsAdmin.name, SessionsAdmin)
    Vue.component(SessionHistoryTable.name, SessionHistoryTable)
    Vue.component(SessionsUser.name, SessionsUser)
    Vue.component(SessionsDevice.name, SessionsDevice)
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
    Vue.component(ClipboardButton.name, ClipboardButton)
  }
}

export default GlobalComponents

<template>
  <div id="app">
    <base-nav container-classes="container-fluid" class="navbar-horizontal navbar-top navbar-expand bg-primary pl-0">
      <b-navbar-brand class="navbar-brand border-right border-gray"><img src="/img/icw.png"></b-navbar-brand>
      <a :href="$route.path" aria-current="page" class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"> {{$route.name}} </a>
      <b-navbar-nav class="align-items-center ml-md-auto">
        <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
        <li class="nav-item d-sm-none">
          <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
            <i class="ni ni-zoom-split-in"></i>
          </a>
        </li>
      </b-navbar-nav>
      <b-navbar-nav class="align-items-center ml-auto ml-md-0" v-if="isAuthenticated">
        <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
          <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
            <b-media no-body class="align-items-center">
              <b-media-body class="ml-2 d-none d-lg-block">
                <i class="ni ni-single-02"></i>
              </b-media-body>
            </b-media>
          </a>
          <template>
            <b-dropdown-item to="/profile">
              <i class="ni ni-single-02"></i>
              <span>Profile</span>
            </b-dropdown-item>
            <b-dropdown-item to="/changePassword">
              <i class="ni ni-key-25"></i>
              <span>Change password</span>
            </b-dropdown-item>
            <b-dropdown-item @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </b-dropdown-item>
          </template>
        </base-dropdown>
      </b-navbar-nav>
    </base-nav>
    <router-view/>
  </div>
</template>

<script>
export default {
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('Logout')
      this.$router.push('/login')
    },
    async profile () {
      this.$router.push({ name: 'Profile' }).catch(failure => {
        console.log(failure)
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

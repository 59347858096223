<template>
  <b-table :fields="fields" :filter="filter" :filter-included-fields="filterOn" :items="items" striped hover sticky-header="calc(100vh - 350px)" no-provider-filtering no-provider-sorting show-empty small @row-clicked="rowHandler"/>
</template>
<script>
export default {
  name: 'region-table',
  props: ['fields', 'filter', 'filterOn', 'items'],
  methods: {
    rowHandler (record, index) {
      this.$router.push({ name: 'Region', params: { id: record.id } })
    }
  }
}
</script>

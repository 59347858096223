<template>
  <bay-telemetry v-if="value.type === 'bay'" :deviceId="value.id"/>
  <cwt-telemetry v-else-if="value.type === 'cwt'" :deviceId="value.id"/>
  <vacc-telemetry v-else-if="value.type === 'vacc'" :deviceId="value.id"/>
  <fmds-telemetry v-else-if="value.type === 'fmdserver'" :deviceId="value.id"/>
  <tex-telemetry v-else-if="value.type === 'tex'" :deviceId="value.id"/>
  <odesk-telemetry v-else-if="value.type === 'odesk'" :deviceId="value.id"/>
  <h1 v-else>Unknown device type: '{{ value.type }}'</h1>
</template>
<script>
export default {
  name: 'device-telemetry',
  props: ['value']
}
</script>

<template>
  <b-table striped hover :fields="fields" :filter="filter" :filter-function="filterFunc" :items="items" @row-clicked="rowHandler" no-provider-filtering no-provider-sorting stacked="md" show-empty small/>
</template>
<script>
export default {
  name: 'object-table',
  props: ['items', 'filter', 'filterFunc'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'region', label: 'Region', sortable: true },
        { key: 'city', label: 'City', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'organization', label: 'Organization', sortable: false },
        { key: 'timeZone', label: 'Time zone', sortable: false },
        { key: 'tokenCost', label: 'Token cost', sortable: true },
        { key: 'rootPassword', label: 'Root password', sortable: false },
        { key: 'dataKey', label: 'Data key', sortable: false },
        { key: 'NTPServer', label: 'NTP server', sortable: false }
      ]
    }
  },
  methods: {
    rowHandler (record, index) {
      this.$router.push({ name: 'Object', params: { id: record.id } })
    }
  }
}
</script>

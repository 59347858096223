import Vue from 'vue'

import Main from './plugins/main-plugin'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

// axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://apiv2.icwstat.com/v3.0/'
// axios.defaults.baseURL = 'http://localhost:3000/v3.0/'

axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch('Logout')
      return router.push('/login').catch(() => {})
    }
  }
  return Promise.reject(error)
})

Vue.use(Main)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Type</b-th>
              <b-th>Number</b-th>
              <b-th>Object</b-th>
              <b-th>Address</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Name'>{{ value.name }} <clipboard-button :value="value.name"/></b-td>
              <b-td class='text-left' stacked-heading='Type'>{{ value.type }}</b-td>
              <b-td class='text-left' stacked-heading='Number'>{{ value.number }}</b-td>
              <b-td class='text-left' stacked-heading='Object'><b-link :to="{name: 'Object', params: {id: value.objectId}}">{{ objectInfo.name }}</b-link> <clipboard-button :value="objectInfo.name"/></b-td>
              <b-td class='text-left' stacked-heading='Address'>{{ objectInfo.country }}, {{ objectInfo.city }}, {{ objectInfo.address }}, {{ objectInfo.organization }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>IP</b-th>
              <b-th>VPN IP</b-th>
              <b-th>Version</b-th>
              <b-th>OS version</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='IP'>{{ formatIP(value.IP) }} <clipboard-button :value="formatIP(value.IP)"/></b-td>
              <b-td class='text-left' stacked-heading='VPN IP'>{{ formatIP(value.VPNIP) }} <clipboard-button :value="formatIP(value.VPNIP)"/></b-td>
              <b-td class='text-left' stacked-heading='Version'>{{ value.version }}</b-td>
              <b-td class='text-left' stacked-heading='OS version'>{{ value.OSVersion }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Created</b-th>
              <b-th>Last data</b-th>
              <b-th>Last activity</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Created'>{{ value.created }}</b-td>
              <b-td class='text-left' stacked-heading='Last data'>{{ value.lastData }}</b-td>
              <b-td class='text-left' stacked-heading='Last activity'>{{ value.lastActivity }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { format } from '../../utils/formatIP.js'
export default {
  name: 'device-info',
  props: ['value', 'objectInfo'],
  methods: {
    formatIP (v) {
      return format(v)
    }
  }
}
</script>

import { get } from './admin'
export const getDevices = async () => {
  try {
    const response = await get('devices')
    return response.data
  } catch (error) {
    return []
  }
}
export const getDevice = async (id) => {
  try {
    const response = await get('devices/' + id)
    return response.data
  } catch (error) {
    return {}
  }
}
export const getDeviceTelemetry = async (type, id) => {
  try {
    switch (type) {
      case 'bay': return (await get('bays/' + id + '/status')).data
      case 'cwt': return (await get('cwts/' + id + '/status')).data
      case 'vacc': return (await get('vaccs/' + id + '/status')).data
      case 'fmds': return (await get('fmdses/' + id + '/status')).data
      case 'tex': return (await get('texes/' + id + '/status')).data
      case 'odesk': return (await get('odesks/' + id + '/status')).data
      default: return {}
    }
  } catch (error) {
    return {}
  }
}
export const getDeviceSessions = async (id) => {
  try {
    const response = await get('devices/' + id + '/sessions/active')
    return response.data
  } catch (error) {
    return []
  }
}
export const getDeviceSessionHistory = async (id, from, to) => {
  try {
    const response = await get('devices/' + id + '/sessions', { from: from, to: to })
    return response.data
  } catch (error) {
    return []
  }
}

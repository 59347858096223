export const certValidity = (nb, na) => {
  const today = new Date()
  if (today < nb) {
    return { valid: false, status: 'inactive', daysRemain: 0 }
  }
  if (today < na) {
    const diff = (na - today) / 86400000
    if (diff < 30) {
      return { valid: true, status: 'expiring', daysRemain: Math.round(diff) }
    }
    return { valid: true, status: 'active', daysRemain: Math.round(diff) }
  }
  return { valid: false, status: 'expired', daysRemain: 0 }
}

<template>
  <b-button class="p-0" size="sm" variant="light" v-b-tooltip.hover :title="tooltip" @click="doCopy">
    <i class="fa-regular fa-copy"></i>
  </b-button>
</template>
<script>
export default {
  name: 'clipboard-button',
  props: ['value'],
  data () {
    return {
      tooltip: 'Copy to clipboard'
    }
  },
  methods: {
    doCopy () {
      navigator.clipboard.writeText(this.value)
      this.tooltip = 'Copied'
      setTimeout(() => { this.tooltip = 'Copy to clipboard' }, 1000)
    }
  }
}
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Country</b-th>
              <b-th>Region</b-th>
              <b-th>Address</b-th>
              <b-th>Organization</b-th>
              <b-th>Time zone</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Name'>{{ value.name }} <clipboard-button :value="value.name"/></b-td>
              <b-td class='text-left' stacked-heading='Country'><b-link v-if="value.countryId" :to="{name: 'Countries', params: {id: value.countryId}}">{{ value.country }}</b-link></b-td>
              <b-td class='text-left' stacked-heading='Region'><b-link v-if="value.regionId" :to="{name: 'Region', params: {id: value.regionId}}">{{ value.region }}</b-link></b-td>
              <b-td class='text-left' stacked-heading='Address'>{{ value.city }}, {{ value.address }}</b-td>
              <b-td class='text-left' stacked-heading='Organization'>{{ value.organization }}</b-td>
              <b-td class='text-left' stacked-heading='Time zone'>{{ value.timeZone }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Token cost</b-th>
              <b-th>Root password</b-th>
              <b-th>Data key</b-th>
              <b-th>NTP sever</b-th>
              <b-th>Notes</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Token cost'>{{ value.tokenCost }}</b-td>
              <b-td class='text-left' stacked-heading='Root password'>{{ value.rootPassword }} <clipboard-button :value="value.rootPassword"/></b-td>
              <b-td class='text-left' stacked-heading='Data key'>{{ value.dataKey }} <clipboard-button :value="value.dataKey"/></b-td>
              <b-td class='text-left' stacked-heading='NTP server'>{{ value.NTPServer }}</b-td>
              <b-td class='text-left' stacked-heading='Notes'>{{ value.notes }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'object-info',
  props: ['value']
}
</script>

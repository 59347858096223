<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :items="items" noProviderSorting stacked="md" show-empty small @row-clicked="rowHandler"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'sessions-user',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'start', label: 'Start', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'login', label: 'Login', sortable: true },
        { key: 'name', label: 'Name', sortable: true }
      ]
    }
  },
  methods: {
    rowHandler (record, index) {
      this.$router.push({ name: 'User', params: { id: record.userId } })
    }
  }
}
</script>

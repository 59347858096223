<template>
  <b-container>
    <b-row>
      <b-col class="m-4">
        <interval-selector @show="getHistory"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :items="items" noProviderSorting stacked="md" show-empty small/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDeviceSessionHistory } from '../../api/devices'
export default {
  name: 'device-session-history',
  props: ['id'],
  data () {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'start', label: 'Start', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true }
      ]
    }
  },
  methods: {
    async getHistory (from, to) {
      const items = await getDeviceSessionHistory(this.id, from, to)
      this.items = items
    }
  }
}
</script>

import axios from 'axios'
const state = {
  token: null
}
const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token
}
const actions = {
  async Login ({ commit }, admin) {
    const resp = await axios.get('token/issue/admin', { auth: { username: admin.email, password: admin.password } })
    console.log("Token received: '" + resp.data + "'")
    await commit('setToken', resp.data)
  },
  async Refresh ({ commit }, token) {
    const resp = await axios.get('token/renew', { headers: { Authorization: 'Bearer ' + token } })
    console.log("Token refreshed: '" + resp.data + "'")
    await commit('setToken', resp.data)
  },
  async Logout ({ commit }) {
    const token = null
    commit('Logout', token)
  }
}
const mutations = {
  setToken (state, token) {
    state.token = token
  },
  Logout (state) {
    state.token = null
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}

import Vue from 'vue'

import VueRouter from 'vue-router'
import jwt from 'jsonwebtoken'

import store from '../store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      console.log('Not authenticated')
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const payload = jwt.decode(store.getters.token)
      const now = Math.round(+new Date() / 1000) // '+ new Date' casts Date object into milliseconds
      if (payload.exp < now) {
        console.log('Authentication expired')
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "admins" */ '../views/HomeView.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        path: '/changePassword',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue')
      },
      {
        path: '/admins',
        name: 'Admins',
        component: () => import(/* webpackChunkName: "admins" */ '../views/AdminsView.vue')
      },
      {
        path: '/admins/:id',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue')
      },
      {
        path: '/countries/:id?',
        name: 'Countries',
        component: () => import(/* webpackChunkName: "countries" */ '../views/CountriesView.vue')
      },
      {
        path: '/regions/:id',
        name: 'Region',
        component: () => import(/* webpackChunkName: "region" */ '../views/RegionView.vue')
      },
      {
        path: '/objects',
        name: 'Objects',
        component: () => import(/* webpackChunkName: "objects" */ '../views/ObjectsView.vue')
      },
      {
        path: '/objects/:id',
        name: 'Object',
        component: () => import(/* webpackChunkName: "object" */ '../views/ObjectView.vue')
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue')
      },
      {
        path: '/users/:id',
        name: 'User',
        component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue')
      },
      {
        path: '/devices',
        name: 'Devices',
        component: () => import(/* webpackChunkName: "devices" */ '../views/DevicesView.vue')
      },
      {
        path: '/devices/:id',
        name: 'Device',
        component: () => import(/* webpackChunkName: "device" */ '../views/DeviceView.vue')
      },
      {
        path: '/sessions',
        name: 'SessionHistory',
        component: () => import(/* webpackChunkName: "sessions" */ '../views/SessionHistoryView.vue')
      },
      {
        path: '/sessions/active',
        name: 'ActiveSessions',
        component: () => import(/* webpackChunkName: "sessions" */ '../views/ActiveSessionsView.vue')
      },
      {
        path: '/subscriptions',
        name: 'Subscriptions',
        component: () => import(/* webpackChunkName: "subscriptions" */ '../views/SubscriptionsView.vue')
      },
      {
        path: '/status',
        name: 'Status',
        component: () => import(/* webpackChunkName: "status" */ '../views/StatusView.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/acceptInvite',
    name: 'AcceptInvite',
    component: () => import(/* webpackChunkName: "acceptInvite" */ '../views/AcceptInvite.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/restorePasswordRequest',
    name: 'RestorePasswordRequest',
    component: () => import(/* webpackChunkName: "restorePasswordRequest" */ '../views/RestorePasswordRequest.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/restorePassword',
    name: 'RestorePassword',
    component: () => import(/* webpackChunkName: "restorePassword" */ '../views/RestorePassword.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

export default routes

export const formatError = e => {
  if (typeof e === 'object') {
    return e.code + ' ' + e.message
  }
  return e
}

export const formatStatus = s => {
  if (s.error) {
    return s.dateTime + ' ' + formatError(s.error)
  }
  return s.dateTime + ' Ok'
}

export const apiProblemLevel = s => {
  if (s.error) {
    return 1
  }
  return 0
}

export const uploadProblemLevel = s => {
  const levels = [apiProblemLevel(s.live), apiProblemLevel(s.bulk), apiProblemLevel(s.api)]
  return Math.max(...levels)
}

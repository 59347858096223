<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group label="Role" label-for="role-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-select v-model="filter.role" :options="roles"/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filter.search" type="search" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter.search" @click="filter.search = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :filter="filter" :filter-function="filterFunc" :items="items" noProviderFiltering noProviderSorting stacked="md" show-empty small @row-clicked="(row, index) => $emit('row-clicked', row, index)"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'user-table',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'login', label: 'Login', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'email', label: 'E-Mail', sortable: true }
      ],
      filter: {
        role: null,
        search: null
      },
      roles: [
        { value: null, text: 'All' },
        { value: 'user', text: 'Users' },
        { value: 'collector', text: 'Collectors' },
        { value: 'operator', text: 'Operators' }
      ]
    }
  },
  methods: {
    filterFunc (row, filter) {
      console.log(JSON.stringify(row))
      if (filter.role !== null) {
        if (row.role !== filter.role) {
          return false
        }
      }
      if (filter.search === null) {
        return true
      }
      const t = row.login.toLowerCase() + row.name.toLowerCase() + row.email.toLowerCase()
      return t.includes(filter.search.toLowerCase())
    }
  }
}
</script>

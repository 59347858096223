<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group label="Type" label-for="type-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-select v-model="filter.type" :options="types"/>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0" >
          <b-input-group size="sm">
            <b-form-input id="filter-input" v-model="filter.search" type="search" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter.search" @click="filter.search = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :fields="fields" :filter="filter" :filter-function="filterFunc" :items="items" noProviderFiltering noProviderSorting stacked="md" show-empty small @row-clicked="rowHandler"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: 'sessions-device',
  props: ['items'],
  data () {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'start', label: 'Start', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'name', label: 'Name', sortable: true }
      ],
      filter: {
        type: null,
        search: null
      },
      types: [
        { value: null, text: 'All' },
        { value: 'bay', text: 'Bays' },
        { value: 'vacc', text: 'Vacuum cleaners' },
        { value: 'tex', text: 'Token exchangers' },
        { value: 'fmdserver', text: 'Fiscal servers' },
        { value: 'odesk', text: 'Operator desktops' }
      ]
    }
  },
  methods: {
    filterFunc (row, filter) {
      if (filter.type !== null) {
        if (row.type !== filter.type) {
          return false
        }
      }
      if (filter.search === null) {
        return true
      }
      const t = row.name.toLowerCase() + row.IP.toLowerCase() + row.VPNIP.toLowerCase()
      return t.includes(filter.search.toLowerCase())
    },
    rowHandler (record, index) {
      this.$router.push({ name: 'Device', params: { id: record.deviceId } })
    }
  }
}
</script>

<template>
  <b-container>
    <b-row>
      <b-col>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>State</b-th>
              <b-th>Balance</b-th>
              <b-th>Balance type</b-th>
              <b-th>Hardware</b-th>
              <b-th>Mifare</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='State'>{{ info.state }}</b-td>
              <b-td class='text-left' stacked-heading='Balance'>{{ info.balance }}</b-td>
              <b-td class='text-left' stacked-heading='Balance type'>{{ info.balanceType }}</b-td>
              <b-td class='text-left' stacked-heading='Hardware'>{{ info.hardware === null ? 'Unknown' : info.hardware ? 'Ok' : 'Failure' }}</b-td>
              <b-td class='text-left' stacked-heading='Mifare'>{{ info.reader }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="info.uploadStatus">
        <h4>Upload status</h4>
        <b-table-simple hower small caption-top stacked>
          <b-thead>
            <b-tr>
              <b-th>Unsent payments</b-th>
              <b-th>Unsent collections</b-th>
              <b-th>Unsent programs</b-th>
              <b-th>Live status</b-th>
              <b-th>Bulk status</b-th>
              <b-th>API status</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class='text-left' stacked-heading='Unsent payments'>{{ info.uploadStatus.unsentPayments }}</b-td>
              <b-td class='text-left' stacked-heading='Unsent collections'>{{ info.uploadStatus.unsentCollections }}</b-td>
              <b-td class='text-left' stacked-heading='Unsent programs'>{{ info.uploadStatus.unsentPrograms }}</b-td>
              <b-td class='text-left' stacked-heading='Live status'>{{ info.uploadStatus.live }}</b-td>
              <b-td class='text-left' stacked-heading='Bulk status'>{{ info.uploadStatus.bulk }}</b-td>
              <b-td class='text-left' stacked-heading='API status'>{{ info.uploadStatus.api }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getDeviceTelemetry } from '../../api/devices'
import { formatStatus } from '../../utils/uploadStatusUtils'
export default {
  name: 'vacc-telemetry',
  props: ['deviceId'],
  data () {
    return {
      info: {}
    }
  },
  async mounted () {
    const info = await getDeviceTelemetry('vacc', this.deviceId)
    if (info.uploadStatus) {
      info.uploadStatus.live = formatStatus(info.uploadStatus.live)
      info.uploadStatus.bulk = formatStatus(info.uploadStatus.bulk)
      info.uploadStatus.api = formatStatus(info.uploadStatus.api)
    }
    this.info = info
  }
}
</script>
